.button{
    background-color:rgb(49, 44, 44);
    --bs-btn-bg: rgb(0, 0, 0);
    --bs-btn-active-bg: rgb(0, 0, 0);
    margin-left: 100px;
    text-align: center;
}

.pfp{
    max-height: 150px;
    margin-top: 30px;
    border-radius: 10%;
    background-color: rgb(226, 162, 22);
}

.intro{
    font-size: 20px;
    margin-top: 30px;
    -webkit-text-stroke-color: rgb(0, 0, 0);
    -webkit-text-stroke-width: 0.2px;
    color: rgb(255, 238, 0);
    outline-color: black;
}

body{
    background-image: "../images/bg/aioceon.png";
    background-color: rgb(70, 79, 82);
}

.header{
    margin-top: 50px;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    color:rgb(216, 200, 148)
    
}

.logo {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.icon{
    max-height: 20px;
    padding-right: 20px;
    color:rgb(255, 255, 255)
}

.col{
    margin: auto;
}

.label{
    font-size: 16px;
    color:rgb(0, 0, 0)
}