body{
    margin-bottom: 0;
    background-color: rgb(255, 255, 255);
    color: #6c757d;
}

#root{
    background-color: rgb(255, 255, 255)
}

.content{
    margin-bottom: 200px;
}