.imageSample {
    display: block;
    height: auto;
    max-width: 100%;
    margin: auto;
    object-fit: contain;
}

body{
    background-color: white;
}

.imageCaption{
    display: block;
    text-align: center;
    margin: auto;
}